const DOUBLE_SLASH_GLOBAL_REGEX = /\/{2,}/g;
const SINGLE_SLASH = "/";

// When a URL contains double slashes // such as:
// - https://shopping.yahoo.com//
// - https://shopping.yahoo.com///foo.html
//
// The page will die due to some issues with Next.JS
// https://github.com/vercel/next.js/issues/70948
//
// We can't redirect this at the server level, because we don't get the /// in the URL
export const fixDoubleSlashes = (location: Location) => {
  const pathname = location.pathname.replace(
    DOUBLE_SLASH_GLOBAL_REGEX,
    SINGLE_SLASH,
  );

  if (pathname === location.pathname) {
    return false;
  }

  const url = new URL(location.href);
  url.pathname = pathname;
  location.href = url.toString();
  return true;
};
