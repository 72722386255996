"use client";

import { beaconError } from "@yahoo-commerce/logger";
import { useEffect } from "react";
import { fixDoubleSlashes } from "@/lib/request/slashes";

interface Props {
  error: Error & { digest?: string };
}

export default function GlobalError({ error }: Props) {
  useEffect(() => {
    if (fixDoubleSlashes(window.location)) {
      return;
    }

    beaconError("global-error", error).finally(() => {
      if (process?.env?.NEXT_PUBLIC_ENVIRONMENT !== "development") {
        window.location.href = "/not-found";
      }
    });
  }, [error]);
}
